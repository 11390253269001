/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

 module.exports = {
  logo: 'vlirdens.svg',
  logoalt: 'vlirdens-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_vlirdens.scss',
  name: 'Vlirdens',
  clientName: 'vlirdens',
  phone: '0888252525',
  email: 'info@vlirdens.nl',
  website: 'https://www.vlirdens.nl',
  glossary: true,
  // langFileName: 'nlc.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#5e35f1',
      color2: '#8c6cff',
      color3: '#9e78e8',
      color4: '#9262bd',
    },
  },
  language: 'nl',
  showAnimations: true,
  showAssetDescription: false,
};
